import React, { createContext, useState, useEffect, useContext } from "react";
import { BASE_URL } from "../App";
import { UserContext } from "./UserProvider";

// Create the context
export const BriefsContext = createContext();

// Create a provider component
export const BriefsProvider = ({ children }) => {
    const { user, loading: userLoading } = useContext(UserContext);
    const [briefs, setBriefs] = useState([]);
    const [ideas, setIdeas] = useState([]);
    const [dashboard, setDashboard] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user || userLoading) return;

        const fetchData = async () => {
            try {
                const [dashboardData = {}] = await Promise.all([
                    fetch(`${BASE_URL}/briefs/`).then((response) => response.json()),
                    // fetch(`${BASE_URL}/ideas/`).then((response) => response.json()),
                    fetch(`${BASE_URL}/dashboard/`).then((response) => response.json()),
                ]);

                // setBriefs(briefsData.briefs);
                // setIdeas(ideasData.ideas);

                setDashboard(dashboardData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user, userLoading]);

    return <BriefsContext.Provider value={{ loading, dashboard }}>{children}</BriefsContext.Provider>;
};
