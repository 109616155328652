import React from "react";
import loadingGif from "../assets/loading.gif"; // Adjust the path

const Loading = () => {
  return (
    <div id="loading-screen">
      <img className="loading" src={loadingGif} alt="Loading..." />
    </div>
  );
};

export default Loading;
